import {useContext} from 'react';

import {Box, IconButton, Typography} from '@material-ui/core';
import {experimentalStyled} from '@material-ui/core/styles';
import {ReactComponent as BackIcon} from '../../../assets/icons/chevron-left.svg';
import {ReactComponent as MenuIcon} from '../../../assets/icons/menu.svg';
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg';
import {DrawerContext} from 'src/contexts/DrawerContext';
import HeaderMenu from './Menu';
import {HeaderContext} from "../../../contexts/HeaderContext";
import HeaderVariant from "../../../enums/HeaderVariant";
import {ReactComponent as TrashIcon} from "../../../assets/icons/trash.svg";
import {ReactComponent as BlockIcon} from "../../../assets/icons/block.svg";
import {ReactComponent as CloseIcon} from "../../../assets/icons/close.svg";
import {ReactComponent as InfoIcon} from "../../../assets/icons/info.svg";
import {ReactComponent as ConnectIcon} from "../../../assets/icons/connect.svg";

const HeaderButton = experimentalStyled(IconButton)(
    () => `
    
    @media (min-width: 1024px) {
        &.with-menu-toggle {
            display: none;
        }
    }
    
    &.with-menu-toggle {
      & .MuiTouchRipple-root {
      display: none;
      }
    }
    
        padding: 0;
        
        & svg {
            height: 25px;
            width: 25px;
        }
    `
)

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        border-bottom-width: 1px;
        border-bottom-color: #B4BEEA;
        border-bottom-style: solid;
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.drawer.width};
            width: auto;
        }
`
);

function Header() {
    const { variant, label, actionLeft, actionRight } = useContext(HeaderContext);
  const { drawerToggle, toggleDrawer } = useContext(DrawerContext);

    const getVariant = () => {
      switch(variant) {
          case HeaderVariant.Back:
              return [
                  <HeaderButton color="primary" onClick={() => {actionLeft()}}>
                      <BackIcon/>
              </HeaderButton>
              ]
          case HeaderVariant.Back_Delete:
              return [<HeaderButton color="primary" onClick={() => {actionLeft()}}>
                  <BackIcon/>
              </HeaderButton>,
                  <HeaderButton color="primary" onClick={() => {actionRight()}}>
                      <TrashIcon/>
                  </HeaderButton>]
          case HeaderVariant.Back_Add:
              return [<HeaderButton color="primary" onClick={() => {actionLeft()}}>
                  <BackIcon/>
              </HeaderButton>,
                  <HeaderButton color="primary" onClick={() => {actionRight()}}>
                      <AddIcon/>
                  </HeaderButton>]
          case HeaderVariant.Menu_Add:
              return [<HeaderButton className={"with-menu-toggle"} color="primary" onClick={() => {typeof actionLeft === 'function' ? actionLeft() : toggleDrawer()}}>
                  {!drawerToggle ? <MenuIcon/> : <CloseIcon/>}
              </HeaderButton>,
                  <HeaderButton color="primary" onClick={() => {actionRight()}}>
                      <AddIcon/>
                  </HeaderButton>]
          case HeaderVariant.Back_Block:
              return [<HeaderButton color="primary" onClick={() => {actionLeft()}}>
                  <BackIcon/>
              </HeaderButton>,
                  <HeaderButton color="primary" onClick={() => {actionRight()}}>
                      <BlockIcon/>
                  </HeaderButton>]
          case HeaderVariant.Menu_Info:
              return [<HeaderButton className={"with-menu-toggle"} color="primary" onClick={() => {typeof actionLeft === 'function' ? actionLeft() : toggleDrawer()}}>
                  {!drawerToggle ? <MenuIcon/> : <CloseIcon/>}
              </HeaderButton>,
                  <HeaderButton color="primary" onClick={() => {actionRight()}}>
                      <InfoIcon/>
                  </HeaderButton>]
          case HeaderVariant.Back_Connect:
              return [<HeaderButton color="primary" onClick={() => {actionLeft()}}>
                  <BackIcon/>
              </HeaderButton>,
                  <HeaderButton color="primary" onClick={() => {actionRight()}}>
                      <ConnectIcon/>
                  </HeaderButton>]
          default:
              return [
                  <HeaderButton className={"with-menu-toggle"} color="primary" onClick={() => {typeof actionLeft === 'function' ? actionLeft() : toggleDrawer()}}>
                      {!drawerToggle ? <MenuIcon/> : <CloseIcon/>}
                  </HeaderButton>
              ]
      }
  }

  const headerVariant = getVariant();

  return (
    <HeaderWrapper sx={{zIndex: 10000, padding: "0"}} display="flex" alignItems="center">
        <Box px={"15px"} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} sx={{height: "100%", width: "100%"}}>
            {headerVariant[0]}
            <HeaderMenu/>
            {label && (
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{width: "100%"}}>
                    <Typography color="#04021D" variant="body1" component="p"
                            sx={{fontSize: "16px", lineHeight: "18px", textAlign: 'center', fontWeight: "medium"}}>
                    {label}
                </Typography>
                </Box>
            )}
            {headerVariant.length > 1 ? headerVariant[1] : <Box sx={{opacity: 0}}>{headerVariant[0]}</Box>}
        </Box>

    </HeaderWrapper>
  );
}

export default Header;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';

import DrawerLayout from 'src/layouts/DrawerLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import LoadingOverlay from "./components/LoadingOverlay";

const Loader = (Component) => (props) => (
  <Suspense fallback={<LoadingOverlay active={false} /> }>
    <Component {...props} />
  </Suspense>
);

// Management

const Users = Loader(lazy(() => import('src/content/management/Users')));
const SingleUser = Loader(lazy(() => import('src/content/management/Users/single')));

const Coupons = Loader(lazy(() => import('src/content/management/Coupons')));
const AddCoupon = Loader(lazy(() => import('src/content/management/Coupons/add')));
const SingleCoupon = Loader(lazy(() => import('src/content/management/Coupons/single')));

const Structures = Loader(lazy(() => import('src/content/management/Structures')));
const SingleStructure = Loader(lazy(() => import('src/content/management/Structures/single')));
const AddStructure = Loader(lazy(() => import('src/content/management/Structures/add')));
const Bookings = Loader(lazy(() => import('src/content/management/Bookings')));
const SingleBooking = Loader(lazy(() => import('src/content/management/Bookings/single')));
// Auth

const LoginCover = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));
const LoginBasic = Loader(lazy(() => import('src/content/pages/Auth/Login/Basic')));

const RegisterCover = Loader(lazy(() => import('src/content/pages/Auth/Register/Cover')));
const RegisterBasic = Loader(lazy(() => import('src/content/pages/Auth/Register/Basic')));
const RegisterWizard = Loader(lazy(() => import('src/content/pages/Auth/Register/Wizard')));

const RecoverPassword = Loader(lazy(() => import('src/content/pages/Auth/RecoverPassword')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));


const routes: PartialRouteObject[] = [
  {
    path: 'account',
    children: [
      {
        path: 'login',
        element: (
          <Guest>
            <LoginCover />
          </Guest>
        )
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterCover />
          </Guest>
        )
      },
      {
        path: 'login-basic',
        element: <LoginBasic />
      },
      {
        path: 'login-cover',
        element: <LoginCover />
      },
      {
        path: 'recover-password',
        element: <RecoverPassword />
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterCover />
          </Guest>
        )
      },
      {
        path: 'register-basic',
        element: <RegisterBasic />
      },
      {
        path: 'register-cover',
        element: <RegisterCover />
      },
      {
        path: 'register-wizard',
        element: <RegisterWizard />
      },
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element:           <Guest>
          <LoginCover />
        </Guest>
      },
      {
        path: '/',
        element: (
            <Navigate
                to="/"
                replace
            />
        )
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                    to="404"
                    replace
                />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'management',
    element: (
      <Authenticated>
        <DrawerLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/users"
            replace
          />
        )
      },
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: <Users />
          },
          {
            path: ':userId',
            element: <SingleUser />
          },
        ]
      },
      {
        path: 'user',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="/management/users"
                replace
              />
            )
          },
          {
            path: ':userId',
            element: <SingleUser />
          },
        ]
      },
      {
        path: 'structures',
        children: [{
          path: '/',
          element: <Structures/>
        },
          {
            path: ':structureId',
            element: <SingleStructure/>
          },
          {
            path: 'add',
            element: <AddStructure/>
          }
        ]
      },
      {
        path: 'structure',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                  to='/management/structures'
                  replace
                />
            )
          }, {
            path: ':structureId',
            element: <SingleStructure/>
          },
        ]
      },
      {
        path: 'coupons',
        children: [{
          path: '/',
          element: <Coupons/>
        },
          {
            path: ":couponId",
            element: <SingleCoupon/>
          }]
      },
      {
        path: 'coupon',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                    to="/management/coupons"
                    replace
                />
            )
          },
          {
            path: '/add',
            element: <AddCoupon/>
          },
          {
            path: ':couponId',
            element: <SingleCoupon/>
          },
        ]
      },
      {
        path: 'bookings',
        children: [
          {
            path: '/',
            element: <Bookings/>
          },
          {
            path: ':bookingId',
            element: <SingleBooking/>
          }
        ]
      }
    ]
  },
  {
    path: 'structure',
    element: (
        <Authenticated>
          <DrawerLayout />
        </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
            <Navigate
                to="/structure/settings"
                replace
            />
        )
      }
    ]
  }
];

export default routes;

import {FC, ReactNode} from 'react';
import { experimentalStyled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Outlet } from 'react-router-dom';

import DrawerIndex from './Drawer';
import Header from './Header';

interface DrawerLayoutProps {
  children?: ReactNode;
}

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.drawer.width};
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const DrawerLayout: FC<DrawerLayoutProps> = () => {

  return (
    <>
      <DrawerIndex />
      <MainWrapper>
        <Header />
          <MainContent id={"root-container"}>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default DrawerLayout;

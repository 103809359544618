import {ReactNode} from 'react';
import {ReactComponent as HomeIcon} from "../../../../assets/icons/menu/menu_home.svg";
import {ReactComponent as BookingsIcon} from "../../../../assets/icons/menu/menu_bookings.svg";
import {ReactComponent as CouponsIcon} from "../../../../assets/icons/menu/menu_coupons.svg";
import {ReactComponent as UsersIcon} from "../../../../assets/icons/menu/menu_users.svg";
import {ReactComponent as StatisticsIcon} from "../../../../assets/icons/menu/menu_statistics.svg";
import {ReactComponent as BookingStatisticsIcon} from "../../../../assets/icons/menu/menu_booking_statistics.svg";
import {ReactComponent as BeachIcon} from "../../../../assets/icons/menu/menu_beach.svg";

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Strutture',
        icon: BeachIcon,
        link: '/management/structures'
      },
      {
        name: 'Utenti',
        icon: UsersIcon,
        link: '/management/users',
      },
      {
        name: 'Coupon',
        icon: CouponsIcon,
        link: '/management/coupons'
      },
    ]
  }
];

export default menuItems;

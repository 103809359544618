import { useRoutes } from 'react-router-dom';
import routes from './router';
import './App.css';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import useScrollTop from 'src/hooks/useScrollTop';
import useAuth from 'src/hooks/useAuth';
import itLocale from 'date-fns/locale/it';
import ThemeProvider from './theme/ThemeProvider';
import {CssBaseline} from '@material-ui/core';

const App = () => {

  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollTop();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
        <CssBaseline />
        {auth.isInitialized ? content : <></>}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;

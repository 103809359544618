import {useContext} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DrawerContext } from 'src/contexts/DrawerContext';
import Logo from 'src/components/Logo';

import {Box, Drawer, Hidden, IconButton} from '@material-ui/core';

import { experimentalStyled } from '@material-ui/core/styles';
import DrawerMenu from './DrawerMenu';
import DrawerUserBox from "./DrawerMenu/UserBox";
import {ReactComponent as CloseIcon} from "../../../assets/icons/close.svg";
import menuItems from "./DrawerMenu/items";
import {ReactComponent as LogoImg} from "../../../assets/images/logo_payoff.svg";

const DrawerWrapper = experimentalStyled(Drawer)(
    ({theme}) => `
    z-index: 100000000010;
        div.MuiDrawer-paper {
        width: 100%;
        }
        `
);

const DrawerContainerWrapper = experimentalStyled(Box)(
  ({ theme }) => `
          color: ${theme.drawer.textColor};
        background: rgba(180, 190, 234, 0.16);
        height: 100%;
          
        @media (max-width: 1024px) {
        width: 100%;
        }
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            width: 280px;
            z-index: 10001;
            border-radius: 0;
            box-shadow: 2px 0px 6px 0 rgb(0 0 0 / 15%);
        }
`
);

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: flex-start;
        margin: 15px 15px 0;
`
);

const HeaderButton = experimentalStyled(IconButton)(
    () => `
        padding: 0;
        
        & svg {
            height: 25px;
            width: 25px;
        }
    `
)

function DrawerIndex() {
  const { drawerToggle, toggleDrawer } = useContext(DrawerContext);
  const closeDrawer = () => toggleDrawer();

  return (
    <>
      <Hidden lgDown>
        <DrawerContainerWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Logo/>
            </TopSection>
            <DrawerMenu menuItems={menuItems} />
            <DrawerUserBox/>
          </Scrollbars>
        </DrawerContainerWrapper>
      </Hidden>
      <Hidden lgUp>
        <DrawerWrapper
          anchor="left"
          open={drawerToggle}
          onClose={closeDrawer}
          variant="temporary"
          elevation={9}
        >
          <DrawerContainerWrapper>
            <Scrollbars autoHide>
              <TopSection sx={{zIndex: 10, position: "sticky", top: 0, margin: 0, borderBottom: "1px solid #B4BEEA", background: "#ffffff"}}>
                <Box px={"15px"} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} sx={{height: "100%", width: "100%"}}>
                <HeaderButton color="primary" onClick={closeDrawer}>
                  {<CloseIcon/>}
                </HeaderButton>
                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{width: "100%", pointerEvents: "none"}}>
                    <Box>
                      <LogoImg width={150}/>
                    </Box>
                  </Box>
                <HeaderButton disabled={true} color="primary" sx={{opacity: 0}}>
                  {<CloseIcon/>}
                </HeaderButton>
                </Box>
              </TopSection>
              <DrawerMenu menuItems={menuItems} />
              <DrawerUserBox/>
            </Scrollbars>
          </DrawerContainerWrapper>
        </DrawerWrapper>
      </Hidden>
    </>
  );
}

export default DrawerIndex;

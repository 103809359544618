import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import axios, {sendGetRequest, sendPostRequest} from 'src/utils/axios';
import {verify, JWT_SECRET, sign, JWT_EXPIRES_IN, decode} from 'src/utils/jwt';
import PropTypes from 'prop-types';
import AuthErrorCode from "../enums/AuthErrorCode";

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  code: AuthErrorCode;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    code: AuthErrorCode;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    isAuthenticated: boolean;
    code: AuthErrorCode;
  }
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitializeAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  code: AuthErrorCode.DEFAULT
};

const setSession = (accessTkSignature: string | null, accessToken: string | null): void => {
  if (accessToken && accessTkSignature) {
    localStorage.setItem('accessToken', JSON.stringify({token: accessToken, signature: accessTkSignature}));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, code } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      code: code
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { isAuthenticated, code } = action.payload;

    return {
      ...state,
      isAuthenticated: isAuthenticated,
      code: code
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    code: AuthErrorCode.DEFAULT
  })
};

const reducer = (state: AuthState, action: Action): AuthState => (
    handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        const tkFromJson = JSON.parse(accessToken);

        if (accessToken && tkFromJson && verify(tkFromJson.signature, JWT_SECRET)) {
          setSession(tkFromJson.signature, tkFromJson.token);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              code: AuthErrorCode.VALID
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              code: AuthErrorCode.DEFAULT
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            code: AuthErrorCode.DEFAULT
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const response = await sendPostRequest<{accessToken: string, code: AuthErrorCode}>("auth/adm/login", {
      email,
      password
    })

    const { accessToken, code } = response;

    if (code === AuthErrorCode.INVALID || !accessToken) {
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: false,
          code: code
        }
      });
      throw TypeError;
    }

    const accessTkSig = sign({id: 1}, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    setSession(accessTkSig, accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        code: code
      }
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null, null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
      <AuthContext.Provider
          value={{
            ...state,
            method: 'JWT',
            login,
            logout
          }}
      >
        {children}
      </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;

import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import { DrawerProvider } from './contexts/DrawerContext';
import { AuthProvider } from './contexts/JWTAuthContext';
import {HeaderProvider} from "./contexts/HeaderContext";
import {LoadingOverlayProvider} from "./contexts/LoadingOverlayContext";

ReactDOM.render(
  <HelmetProvider>
      <LoadingOverlayProvider>
          <DrawerProvider>
              <HeaderProvider>
                  <BrowserRouter>
                      <AuthProvider>
                          <App/>
                      </AuthProvider>
                  </BrowserRouter>
              </HeaderProvider>
          </DrawerProvider>
      </LoadingOverlayProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();

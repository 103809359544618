import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { experimentalStyled } from '@material-ui/core/styles';
import {ReactComponent as LogoutIcon} from "../../../../../assets/icons/menu/menu_logout.svg";
import {DrawerContext} from "../../../../../contexts/DrawerContext";
import {sendGetRequest} from "../../../../../utils/axios";
import type {Structure} from "../../../../../models/structure";
import useRefMounted from "../../../../../hooks/useRefMounted";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        border-top: 1px dashed #697ED5;
        margin: 0 15px;
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
  padding-top: 30px;
        font-weight: ${theme.typography.fontWeightBold};
        color: #09043E;
        font-size: 16px;
        display: block;
        text-align: center;
`
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: #6B688B;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
`
);

function DrawerUserBox() {
  const isMountedRef = useRefMounted();
  const { drawerToggle, toggleDrawer } = useContext(DrawerContext);
  const [structureSettings, setStructureSettings] = useState<Structure>(null);
  const closeDrawer = () => toggleDrawer();

  const { t }: { t: any } = useTranslation();

  const navigate = useNavigate();

  const { logout } = useAuth();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      closeDrawer();
      navigate('/account/login');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxText>
        <UserBoxLabel variant="body1">{"Beachnow Superadmin"}</UserBoxLabel>
        <UserBoxDescription variant="body2">
          {"Lido bianco"}
        </UserBoxDescription>
      </UserBoxText>
      <Box sx={{ m: 1 }}>
        <Button color="primary" fullWidth onClick={handleLogout}>
          <LogoutIcon/>
          <Box sx={{ml: 1}}>
          {"Logout"}
          </Box>
        </Button>
      </Box>
    </>
  );
}

export default DrawerUserBox;
